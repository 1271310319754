

	import { Component, Inject, Prop, Vue } from "vue-property-decorator";
	import { share } from "@common/jsInterface";

	import type { MerchantKioskModel } from "@common/models/kiosk";
	import type Toast from "@common/components/widget/Toast.vue";

	@Component
	export default class extends Vue {
		$refs!: {
			toast: Toast;
		};

		@Inject() public readonly alert!: AlertMethod;
		@Inject() public readonly confirm!: ConfirmMethod;
		@Prop(String) public id!: string;

		protected data: MerchantKioskModel | null = null;

		mounted(): void {
			this.load();
		}

		protected get url(): string {
			if(!this.data) return "";
			return location.origin + "/link/" + this.data.Id;
		}

		private async load() {
			try {
				this.data = await post<MerchantKioskModel>(
					"/api/Device/CreateShare",
					{ Id: this.id }
				);
			} catch(e: unknown) {
				if(e instanceof Error) await this.alert(e.message, "讀取失敗", "error");
				this.$router.back();
			}
		}

		protected share(): void {
			share(this.url, undefined, "LeapCoin 裝置分享");
		}

		protected onCopy(): void {
			this.$refs.toast.show();
		}
	}
