

	import { Component, Inject, Prop } from "vue-property-decorator";
	import Modal from "@/components/modal";

	@Component
	export default class extends Modal {
		@Inject() public readonly alert!: AlertMethod;

		public code: string | null = null;
		public inputMode: boolean = false;

		protected title: string = "";

		public async submit(): Promise<void> {
			try {
				await post('/api/Device/add', { Id: this.code, Title: this.title });
				this.close();
				this.$router.back();
			} catch(e: unknown) {
				if(e instanceof Error) await this.alert(e.message, '新增失敗', 'error');
			}
		}

		public showCode(code?: string): void {
			this.code = code || null;
			this.inputMode = !code;
			this.show();
		}
	}
